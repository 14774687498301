<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          {{ person.first_name }} {{ person.last_name }}
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link :to="`/admin/personnel-edit/${person.id}`">
          <v-btn small>Edit</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col4">
        <p>
          Adress:<br />
          <span v-if="person.address">{{ person.address }}</span>
          <span v-if="person.post_code"><br />{{ person.post_code }}</span>
        </p>
      </v-col>
      <v-col class="col4">
        <p>
          Contacts:<br />
          <span v-if="person.telephone">Telephone: {{ person.telephone }}<br /></span>
          <span v-if="person.mobile">Mobile: {{ person.mobile }}<br /></span>
          <span v-if="person.email">Email: {{ person.email }}</span>
        </p>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col4">
        <p>
          Next of kin:<br />
          <span v-if="person.next_of_kin">{{ person.next_of_kin }}</span>
          <span v-if="person.next_of_kin_relationship"><br />{{ person.next_of_kin_relationship }}</span>
          <span v-if="person.next_of_kin_telephone"><br />{{ person.next_of_kin_telephone }}</span>
        </p>
      </v-col>
      <v-col class="col4">
        <p>
          Doctor:<br />
          <span v-if="person.doctor">{{ person.doctor }}<br /></span>
          <span v-if="person.surgery_address">{{ person.surgery_address }}<br /></span>
        </p>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col4">
        <p>
          Date of Birth:<br />
          <span v-if="person.date_of_birth">{{ person.date_of_birth | prettyDate }}</span>
        </p>
      </v-col>
      <v-col class="col4">
        <p>
          Start Date:<br />
          <span v-if="person.start_date">{{ person.start_date | prettyDate }}<br /></span>
        </p>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col4">
        <p>
          NI Number:<br />
          <span v-if="person.ni_number">{{ person.ni_number }}</span>
        </p>
      </v-col>
      <v-col class="col4">
        <p>
          Holiday entitlement:<br />
          <span v-if="person.holiday_entitlement">{{ person.holiday_entitlement }}<br /></span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col4">
        <p v-if="person.admin">This person is an admin</p>
        <p v-if="!person.admin">This person is not an admin</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col4">
        <p>
          Is this person active?<br />
          <span v-if="person.active">Yes</span>
          <span v-else>No</span>
        </p>
      </v-col>
    </v-row>
    <div v-if="person.crew_member">
      <v-row
        v-if="person.crew_member.id"
        class="pt-5">
        <v-col class="col4">
          <p>This person is a crew member</p>
        </v-col>
      </v-row>
      <v-row
        class="pt-5"
        v-if="person.crew_member.id">
        <v-col class="col4">
          <p>
            Usual vehicle:<br />
            <span v-if="person.crew_member.usual_vehicle">{{ person.crew_member.usual_vehicle }}</span>
          </p>
        </v-col>
        <v-col class="col4">
          <p>
            Pay details:<br />
            <span v-if="person.crew_member.hourly_rate">Hourly rate: {{ person.crew_member.hourly_rate }}<br /></span>
            <span v-if="person.crew_member.bonus">
              Paid bonus?
              <span v-if="person.crew_member.bonus === true">Yes</span>
              <span v-else>No</span>
            <br /></span>
          </p>
        </v-col>
      </v-row>
      <v-row
        class="pt-5"
        v-if="person.crew_member.id">
        <v-col class="col4">
          <p>
            Is this person a team leader?<br />
            <span v-if="person.crew_member.team_leader === true">Yes</span>
            <span v-else>No</span>
          </p>
        </v-col>
        <v-col class="col4">
          <p>
            Department:<br />
            <span v-if="person.crew_member.department_id">
              <span v-if="person.crew_member.department_id === 1">Paint</span>
              <span v-else>Thermo</span>
            </span>
            <span v-else>Both</span>
          </p>
        </v-col>
      </v-row>
      <v-row
        class="pt-5"
        v-if="person.crew_member.id">
        <v-col class="col4">
          <p>
            Maximum shift length<br />
            {{ person.crew_member.max_shift_length }} hours.
          </p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminPersonnelView',
  data() {
    return {
      person: {},
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    getPerson() {
      const personId = this.$route.params.id;
      axios.get(`/personnel/getSingle/${personId}.json?token=${this.token}`)
        .then((response) => {
          this.person = response.data.person;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPerson();
  },
};
</script>
